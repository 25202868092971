import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ProfileStatus } from '@src/app/core/interfaces/profile-status.enum';
import { AuthService, LocalStorageService } from '@src/app/core/services';
import { catchError, map, mergeMap, of, tap } from 'rxjs';
import { AuthRepository } from '../auth.repository';
import { login, loginFailure, loginSuccess, register, registerFailure, registerSuccess, resendVerification, resendVerificationSuccess, verifyToken, verifyTokenFailure, verifyTokenSuccess } from './auth.actions';

@Injectable()
export class AuthEffects {
  constructor(
    private readonly router: Router,
    private readonly actions$: Actions,
    private readonly authRepository: AuthRepository,
    private readonly localStorageService: LocalStorageService,
    private readonly authService: AuthService,
) {}

  login$ = createEffect(() =>
    this.actions$.pipe(
      ofType(login),
      mergeMap(({ email, password }) =>
        this.authRepository.login({ email, password }).pipe(
          map((response) => loginSuccess({ user: response.user, token: response.accessToken })),
          catchError((error) => of(loginFailure({ error: error.error.message })))
        )
      )
    )
  );

  loginSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(loginSuccess),
        tap(({ user, token }) => {
          if (user) {
            const isDesigner = user.roles[0].name.some((role: string) => role === 'designer');
            this.localStorageService.setDataInLocalStorage({
              key: 'authToken',
              value: token,
            });

            this.authService.setTokenExpiryTime(token);

            this.localStorageService.setDataInLocalStorage({
              key: 'user',
              value: user,
            });
            if (isDesigner) {
              this.localStorageService.setDataInLocalStorage({
                key: 'role',
                value: 'designer',
              });
              if(user.profileStatus == ProfileStatus.INCOMPLETE){
                this.router.navigate(['/create-profile']);
              } 
              else if(user.profileStatus == ProfileStatus.NEEDS_REWORK){
                this.router.navigate(['/edit-profile']);
              } 
              else{
                this.router.navigate(['/professionals']);
              }
            } else {
              this.localStorageService.setDataInLocalStorage({
                key: 'role',
                value: 'homeowner',
              });
              this.router.navigate(['/']);
            }
          }
        })
      ),
    { dispatch: false }
  );

  // logout$ = createEffect(
  //   () =>
  //     this.actions$.pipe(
  //       ofType(logout),
  //       tap(() => {
  //         console.log(logout);
  //         // this.authRepository.logout(token.token)
  //         this.localStorageService.removeDataInLocalStorage('authToken');
  //         this.localStorageService.removeDataInLocalStorage('user');
  //         this.localStorageService.removeDataInLocalStorage('role');
  //         // Redirect to login page
  //         // this.router.navigate(['/auth/sign-in']);
  //       })
  //     ),
  //   { dispatch: false }
  // );

  register$ = createEffect(() =>
    this.actions$.pipe(
      ofType(register),
      mergeMap(({ payload }) =>
        this.authRepository.register(payload).pipe(
          map((response) => registerSuccess({ response })),
          catchError((error) => of(registerFailure({ error: error.error.message })))
        )
      )
    )
  );

  registerSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(registerSuccess),
        tap(({ response}) => {
          const email = response.email; // Adjust according to your response structure
          this.router.navigate(['/auth/verification'], { queryParams: { email } });
        })
      ),
    { dispatch: false }
  );

  verifyToken$ = createEffect(() =>
    this.actions$.pipe(
      ofType(verifyToken),
      mergeMap(({token}) =>
        this.authRepository.verifyToken(token).pipe(
          map((response) => verifyTokenSuccess({ message: response.message})),
          catchError((error) => of(verifyTokenFailure({ error: error.error.message })))
        )
      )
    )
  );

  verifyTokenSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(verifyTokenSuccess),
        tap(({ message }) => {
          // this.router.navigate(['/']);
        })
      ),
    { dispatch: false }
  );

  resendVerification$ = createEffect(() =>
    this.actions$.pipe(
      ofType(resendVerification),
      mergeMap(({email}) =>
        this.authRepository.resendVerification(email).pipe(
          map((response) => resendVerificationSuccess({ message: response})),
          catchError((error) => of(verifyTokenFailure({ error: error.error.message })))
        )
      )
    )
  );

  resendVerificationSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(resendVerificationSuccess),
        tap(({ message }) => {
          // this.router.navigate(['/']);
        })
      ),
    { dispatch: false }
  );
}
